import React from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import Question from "./Question";
import Content from "./layout/Content";
import Info from "./Info";
import img from "../images/onboarding_vegovalet.png";
import styles from "../styles/Intro.module.css";

const API_URL = process.env.REACT_APP_API_BASEURL;
const AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN;

function Intro({ onCompleted }) {
  const { getAccessTokenSilently } = useAuth0();
  const { teamId } = useParams();
  const [currentValues, setCurrentValues] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [slide, setSlide] = React.useState(0);

  function onSubmitCurrent(e) {
    e.preventDefault();
    const elements = Array.from(e.currentTarget);
    const answers = elements
      .filter((item) => item.type === "range")
      .map((item) => parseInt(item.value));

    if (answers && answers.length === 3) {
      setCurrentValues(answers);
      setSlide((prev) => prev + 1);
    }
  }

  async function onSubmit(e) {
    e.preventDefault();
    const elements = Array.from(e.currentTarget);
    const goalValues = elements
      .filter((item) => item.type === "range")
      .map((item) => parseInt(item.value));

    if (goalValues && goalValues.length === 3) {
      setLoading(true);
      try {
        const accessToken = await getAccessTokenSilently({
          audience: `https://${AUTH_DOMAIN}/api/v2/`,
          scope: "read:current_user",
        });
        if (accessToken) {
          const { data } = await axios.post(
            `${API_URL}/teams/${teamId}/intro`,
            {
              current: currentValues,
              goal: goalValues,
            },
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          if (data && data.hasOwnProperty("intro")) {
            onCompleted();
          }
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    }
  }

  return slide === 0 ? (
    <Content>
      <div className={styles.intro}>
        <div>
          <div className={styles.introImage}></div>
          <h2 className={styles.introHeading}>Välkommen till Vegovalet!</h2>
          <p className={styles.introText}>
            Vad kul att du vill utmana dig själv och andra att äta lite grönare!
          </p>
          <img
            className={styles.introShowcase}
            src={img}
            alt="3 telefoner som visar upp Vegovalet"
          />
        </div>
        <div className={styles.introAction}>
          <button
            className={styles.introButton}
            onClick={() => setSlide((prev) => prev + 1)}
            type="button"
          >
            Såhär funkar vegovalet!
          </button>
        </div>
      </div>
    </Content>
  ) : slide === 1 ? (
    <Content>
      <div className={styles.intro}>
        <div>
          <div className={styles.firstImage}></div>
          <h2 className={styles.introHeading}>
            Varje dag självskattar du tre enkla parametrar
          </h2>
          <p className={styles.introText}>
            Parameter 1<br />
            Hur många gram frukt och grönt har du ätit idag?
          </p>
          <p className={styles.introText}>
            Hur mycket är 500 gram frukt & grönt?
            <br />
            500 gram är det som ryms i två kupade händer. Eller ca fem knytnävar
            om dagen. Ex. ca 100 gram: en skalad banan, ett halvt äpple, en
            tomat, en paprika, ett halvt paket kokta linser
          </p>
          <p className={styles.introText}>
            Vad räknas som frukt & grönsaker?
            <br />
            Färskt, skafferi, fryst - allt räknas! Linserna i köttfärssåsen, de
            rivna morötterna och krossade tomaterna i grytan och broccolin i
            fiskgratängen är precis lika mycket värda. Blåbären, bananen eller
            grönkålen i frukost-smoothien också! Potatis räknas inte (ingår ej i
            Livsmedelsverkets rekommendation).
          </p>
        </div>
        <div className={styles.introAction}>
          <button
            className={styles.introButton}
            onClick={() => setSlide((prev) => prev + 1)}
            type="button"
          >
            Super, jag förstår!
          </button>
        </div>
      </div>
    </Content>
  ) : slide === 2 ? (
    <Content>
      <div className={styles.intro}>
        <div>
          <div className={styles.secondImage}></div>
          <h2 className={styles.introHeading}>Vadå vego?</h2>
          <p className={styles.introText}>
            Parameter 2<br />
            Hur många vego-måltider har du ätit idag (av tre möjliga:
            frukost/mellanmål, lunch, middag)?
          </p>
          <p className={styles.introText}>
            En måltid räknas som vego om den utesluter kött, fisk och fågel (men
            kan alltså inkludera ägg och mejeriprodukter, ex. ost, smör och
            grädde)
          </p>
          <p className={styles.introText}>
            Inte vego: Kött, kyckling, fisk. Vego: Ägg, mejeriprodukter
          </p>
        </div>
        <div className={styles.introAction}>
          <button
            className={styles.introButton}
            onClick={() => setSlide((prev) => prev + 1)}
            type="button"
          >
            Jag fattar (grönsaks)biffen!
          </button>
        </div>
      </div>
    </Content>
  ) : slide === 3 ? (
    <Content>
      <div className={styles.intro}>
        <div>
          <div className={styles.thirdImage}></div>
          <h2 className={styles.introHeading}>
            För dig som vill ha en utmaning!
          </h2>
          <p className={styles.introText}>
            Parameter 3<br />
            Hur många av vego-måltiderna var helt växtbaserade?
          </p>
          <p className={styles.introText}>
            En måltid räknas som helt växtbaserad om den också utesluter ägg och
            mejeriprodukter.
          </p>
          <p className={styles.introText}>
            Inte växtbaserat: Ägg, mejeriprodukter, kött, kyckling, fisk.
          </p>
          <p className={styles.introText}>
            Växtbaserat: Burgare gjord på baljväxter, linsbolognese,
            havre-crèmefraiche och självklart all frukt & grönt!
          </p>
          <p className={styles.introText}>
            Genom att äta fler växtbaserade måltider kan vi göra stor skillnad
            för vårt klimatavtryck. De flesta äter minst tre måltider om dagen,
            så det finns tre chanser varje dag att testa dig fram och hitta en
            balans som passar dig!
          </p>
        </div>
        <div className={styles.introAction}>
          <button
            className={styles.introButton}
            onClick={() => setSlide((prev) => prev + 1)}
            type="button"
          >
            Nu kör vi!
          </button>
        </div>
      </div>
    </Content>
  ) : slide === 4 ? (
    <div className={styles.question}>
      <div className={styles.questionHeader}></div>
      <form onSubmit={onSubmitCurrent} className={styles.questionContent}>
        <div>
          <span className={styles.questionLabel}>Nuläge</span>
          <h2 className={styles.questionHeading}>
            Hur äter du i dagsläget en vanlig dag eller vecka?
          </h2>
          <div className={styles.questionForm}>
            <Question
              item={{
                id: 0,
                question: "Hur många gram frukt & grönsaker per dag?",
                min: 0,
                max: 800,
                label: "g",
              }}
              answer={0}
            />
            <Question
              item={{
                id: 1,
                question: "Hur många vego-måltider äter du per vecka?",
                min: 0,
                max: 21,
                label: "st",
              }}
              answer={0}
            />
            <Question
              item={{
                id: 2,
                question:
                  "Hur många av vego-måltiderna brukar vara helt växtbaserade?",
                min: 0,
                max: 21,
                label: "st",
              }}
              answer={0}
            />
          </div>
        </div>
        <div className={styles.questionAction}>
          <button className={styles.questionButton} type="submit">
            Fortsätt
          </button>
        </div>
      </form>
      <Info />
    </div>
  ) : slide === 5 ? (
    <div className={styles.question}>
      <div className={styles.questionHeader}></div>
      <form onSubmit={onSubmit} className={styles.questionContent}>
        <div>
          <span className={styles.questionLabel}>Målsättning</span>
          <h2 className={styles.questionHeading}>
            Hur skulle du vilja äta en vanlig dag eller vecka?
          </h2>
          <div className={styles.questionForm}>
            <Question
              item={{
                id: 3,
                question:
                  "Hur många gram frukt & grönsaker vill du äta per dag? (Minst 500 gram rekommenderas)",
                min: 0,
                max: 800,
                label: "g",
              }}
              answer={0}
            />
            <Question
              item={{
                id: 4,
                question: "Hur många vego-måltider vill du äta per vecka?",
                min: 0,
                max: 21,
                label: "st",
              }}
              answer={0}
            />
            <Question
              item={{
                id: 5,
                question:
                  "Hur många av vego-måltiderna vill du ska vara helt växtbaserade?",
                min: 0,
                max: 21,
                label: "st",
              }}
              answer={0}
            />
          </div>
        </div>
        <div className={styles.questionAction}>
          <button
            disabled={loading}
            className={styles.questionButton}
            type="submit"
          >
            Fortsätt
          </button>
        </div>
      </form>
      <Info />
    </div>
  ) : null;
}

export default Intro;
