import React from "react";
import axios from "axios";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Leaderboard from "../components/Leaderboard";
import Page from "../components/layout/Page";
import NotStarted from "../components/NotStarted";
import highfive from "../images/highfive.svg";
import styles from "../styles/Status.module.css";

const API_URL = process.env.REACT_APP_API_BASEURL;
const AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN;

function Status() {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { teamId } = useParams();
  const navigate = useNavigate();
  const [navItem, setNavItem] = React.useState("STATUS");
  const [current, setCurrent] = React.useState([]);
  const [teamResult, setTeamResult] = React.useState([]);
  const [today, setToday] = React.useState([]);
  const [goal, setGoal] = React.useState([]);
  const [fetching, setFetching] = React.useState(false);
  const [fetchingIntro, setFetchingIntro] = React.useState(false);
  const [introCompleted, setIntroCompleted] = React.useState(false);
  const [started, setStarted] = React.useState(true);
  const [startDate, setStartDate] = React.useState(null);
  const [hasEnded, setHasEnded] = React.useState(false);

  React.useEffect(() => {
    let componentMounted = true;

    async function getTeamsIntro() {
      setFetchingIntro(true);

      try {
        const accessToken = await getAccessTokenSilently({
          audience: `https://${AUTH_DOMAIN}/api/v2/`,
          scope: "read:current_user",
        });

        if (accessToken) {
          const { data } = await axios.get(`${API_URL}/teams/${teamId}/intro`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          if (data && componentMounted) {
            setHasEnded(data?.hasEnded);
            if (data?.hasStarted === false) {
              setStartDate(data.dateFrom);
              setStarted(false);
            } else if (data?.showIntroQuestions) {
              navigate(`/teams/${teamId}/questions`);
            } else {
              setIntroCompleted(true);
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
      setFetchingIntro(false);
    }
    getTeamsIntro();

    return () => {
      componentMounted = false;
    };
  }, [getAccessTokenSilently, teamId, navigate]);

  React.useEffect(() => {
    let componentMounted = true;

    async function getProgressData() {
      setFetching(true);
      try {
        const accessToken = await getAccessTokenSilently({
          audience: `https://${AUTH_DOMAIN}/api/v2/`,
          scope: "read:current_user",
        });

        if (accessToken) {
          const { data } = await axios.get(
            `${API_URL}/teams/${teamId}/progress`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          if (data && componentMounted) {
            setCurrent(data.current);
            setTeamResult(data.team);
            setGoal(data.goals);
            setToday(data.today);
          }
        }
      } catch (error) {
        console.log(error);
      }
      setFetching(false);
    }
    if (introCompleted === true) {
      getProgressData();
    }

    return () => {
      componentMounted = false;
    };
  }, [introCompleted, teamId, getAccessTokenSilently]);

  function getPercentageFrom(item, index) {
    let result = 0.001;

    try {
      const percentage = item / goal[index];
      if (percentage) {
        result = percentage * 100;
      }
    } catch (error) {
      console.log("Failed to parse", error);
    }
    return result;
  }

  function getNumberFrom(value) {
    if (value && typeof value === "number") {
      return value.toFixed(2).replace(".", ",");
    } else {
      return value;
    }
  }

  return (
    isAuthenticated &&
    (started === false ? (
      <NotStarted startDate={startDate} />
    ) : (
      <div>
        {fetching || fetchingIntro ? (
          <div className={styles.loading}>
            <p className={styles.loadingText}>Laddar data…</p>
          </div>
        ) : (
          <Page>
            <div className={styles.navbar}>
              <button
                className={
                  navItem === "STATUS"
                    ? styles.activeNavButton
                    : styles.navButton
                }
                onClick={() => setNavItem("STATUS")}
                type="button"
              >
                Min sida
              </button>
              <button
                className={
                  navItem === "LEADERBOARD"
                    ? styles.activeNavButton
                    : styles.navButton
                }
                onClick={() => setNavItem("LEADERBOARD")}
                type="button"
              >
                Topplista
              </button>
            </div>
            {navItem === "STATUS" ? (
              <>
                {hasEnded === true ? (
                  <>
                    <img
                      className={styles.endedIcon}
                      alt="High Fice"
                      src={highfive}
                    />
                    <h3 className={styles.endedHeading}>High five!</h3>
                    <p className={styles.endedSubHeading}>
                      Vegovalet är slut för denna gång. Bra jobbat! Här kommer
                      en sammanfattning.
                    </p>
                  </>
                ) : (
                  <>
                    <h3 className={styles.mainHeading}>Dagens resultat</h3>
                    <p className={styles.subLessHeading}>
                      Stäng dina ringar varje dag!
                      <br />
                      En full ring = uppnått mål
                    </p>
                  </>
                )}

                <div className={styles.charts}>
                  {hasEnded === true ? (
                    <>
                      {current.map((item, index) => {
                        const percent = getPercentageFrom(item, index);
                        return percent && percent >= 0 ? (
                          <div className={styles.chart} key={index}>
                            <svg
                              className={styles.chartSvg}
                              viewBox="0 0 36 36"
                            >
                              <path
                                className={styles.circle}
                                d="M18 2.0845
                                a 15.9155 15.9155 0 0 1 0 31.831
                                a 15.9155 15.9155 0 0 1 0 -31.831"
                              />
                              <path
                                className={styles.fill}
                                d="M18 2.0845
                                a 15.9155 15.9155 0 0 1 0 31.831
                                a 15.9155 15.9155 0 0 1 0 -31.831"
                                fill="none"
                                strokeDasharray={`${percent}, 100`}
                              />
                            </svg>
                          </div>
                        ) : null;
                      })}
                    </>
                  ) : (
                    <>
                      {today.map((item, index) => {
                        const percent = getPercentageFrom(item, index);
                        return percent && percent >= 0 ? (
                          <div className={styles.chart} key={index}>
                            <svg
                              className={styles.chartSvg}
                              viewBox="0 0 36 36"
                            >
                              <path
                                className={styles.circle}
                                d="M18 2.0845
                            a 15.9155 15.9155 0 0 1 0 31.831
                            a 15.9155 15.9155 0 0 1 0 -31.831"
                              />
                              <path
                                className={styles.fill}
                                d="M18 2.0845
                            a 15.9155 15.9155 0 0 1 0 31.831
                            a 15.9155 15.9155 0 0 1 0 -31.831"
                                fill="none"
                                strokeDasharray={`${percent}, 100`}
                              />
                            </svg>
                          </div>
                        ) : null;
                      })}
                    </>
                  )}
                </div>
                {hasEnded !== true && (
                  <div className={styles.links}>
                    <Link
                      className={styles.questionsButton}
                      to={`/teams/${teamId}/questions`}
                    >
                      Registrera måltider
                    </Link>
                  </div>
                )}
                {current && current.length === 3 && (
                  <div className={styles.status}>
                    <h3 className={styles.statusHeading}>Mina snittresultat</h3>
                    <ul className={styles.statusList}>
                      <li className={styles.statusItem}>
                        <div>
                          <span className={styles.name}>Frukt och grönt</span>{" "}
                          <p className={styles.goal}>Mål</p>
                        </div>
                        <div>
                          <span>{getNumberFrom(current[0])} gram/dag</span>
                          <p className={styles.goalright}>
                            {getNumberFrom(goal[0])} gram/dag
                          </p>
                        </div>
                      </li>
                      <li className={styles.statusItem}>
                        <div>
                          <span className={styles.name}>Vego</span>
                          <p className={styles.goal}>Mål</p>
                        </div>
                        <div>
                          <span>{getNumberFrom(current[1])} måltider/dag</span>
                          <p className={styles.goalright}>
                            {getNumberFrom(goal[1])} måltider/dag
                          </p>
                        </div>
                      </li>
                      <li className={styles.statusItem}>
                        <div>
                          <span className={styles.name}>Helt växtbaserat</span>
                          <p className={styles.goal}>Mål</p>
                        </div>
                        <div>
                          <span>{getNumberFrom(current[2])} måltider/dag</span>
                          <p className={styles.goalright}>
                            {getNumberFrom(goal[2])} måltider/dag
                          </p>
                        </div>
                      </li>
                    </ul>
                    {teamResult && teamResult.length === 3 && (
                      <>
                        <h3 className={styles.statusHeading}>
                          Teamets snittresultat
                        </h3>
                        <ul className={styles.statusList}>
                          <li className={styles.statusItem}>
                            <div>
                              <span className={styles.name}>
                                Frukt och grönt
                              </span>
                            </div>
                            <div>
                              <span>
                                {getNumberFrom(teamResult[0])} gram/dag
                              </span>
                            </div>
                          </li>
                          <li className={styles.statusItem}>
                            <div>
                              <span className={styles.name}>Vego</span>
                            </div>
                            <div>
                              <span>
                                {getNumberFrom(teamResult[1])} måltider/dag
                              </span>
                            </div>
                          </li>
                          <li className={styles.statusItem}>
                            <div>
                              <span className={styles.name}>
                                Helt växtbaserat
                              </span>
                            </div>
                            <div>
                              <span>
                                {getNumberFrom(teamResult[2])} måltider/dag
                              </span>
                            </div>
                          </li>
                        </ul>
                      </>
                    )}
                  </div>
                )}
                <div className={styles.inspiration}>
                  <p className={styles.inspirationText}>
                    Kicka igång Vegovalet! På vår inspirationssida hittar du
                    tips och trix.
                  </p>
                  <a
                    className={styles.inspirationButton}
                    href="https://www.ica.se/kampanj/vegovalet/"
                  >
                    Till inspiration
                  </a>
                </div>
              </>
            ) : navItem === "LEADERBOARD" ? (
              <Leaderboard />
            ) : null}
          </Page>
        )}
      </div>
    ))
  );
}
export default Status;
