import styles from '../../styles/Page.module.css'

function Page({ children, ...rest }) {

  return (
    <div {...rest} className={styles.page}>
      {children}
    </div>
  )
}

export default Page