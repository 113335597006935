import React from "react";
import Slider from "./Slider";
import styles from "../styles/Question.module.css";

function Question({ item, index, answer }) {
  const { id, question, min, max, label } = item;
  const [value, setValue] = React.useState(0);

  function getColorByIndex() {
    let color = "#e3000b";

    switch (index) {
      case 0:
        color = "#e3000b";
        break;
      case 1:
        color = "#009738";
        break;
      case 2:
        color = "#0068a8";
        break;
      default:
        color = "#e3000b";
        break;
    }
    return color;
  }

  React.useEffect(() => {
    setValue(answer)
  }, [answer])

  return (
    <div className={styles.question}>
      <div className={styles.header}>
        <h3 className={styles.heading}>{question}</h3>
        <span style={{ color: getColorByIndex() }} className={styles.answer}>
          {value} {label}
        </span>
      </div>
      <Slider
        label={label}
        color={getColorByIndex()}
        min={min}
        max={max}
        value={value || 0}
        onChange={(e) => setValue(e.target.value)}
        name={id}
      />
    </div>
  );
}

export default Question;
