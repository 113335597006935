import React from "react"
import styles from '../styles/Info.module.css'

function Info() {
  return (
    <div className={styles.info}>
      <div className={styles.image}></div>
      <div className={styles.content}>
        <h5 className={styles.contentHeading}>Hur mycket är 500g frukt & grönt?</h5>
        <div className={styles.contentDescription}>
          <p>Två kupade händer (se bild)</p>
          <p>Eller ca fem knytnävar om dagen</p>
          <p>Ex. två knytnävsstora frukter + tre knytnävar grönsaker - klart!</p>
        </div>

        <h5 className={styles.contentHeading}>Vad räknas som frukt & grönt?</h5>
        <div className={styles.contentDescription}>
          <p>Färskt, skafferi, fryst - allt räknas!</p>
          <p>Ex. färsk frukt, krossade tomater, linser, frysta grönsaker</p>
          <p>Inte potatis (ej del av Livsmedelsverkets rekommendation)</p>
        </div>


        <h5 className={styles.contentHeading}>Vad räknas som vego?</h5>
        <div className={styles.contentDescription}>
          <p>Inget kött/djur</p>
          <p>Inte vego: Kött, kyckling, fisk</p>
          <p>Vego: Ägg, mejeriprodukter</p>
        </div>

        <h5 className={styles.contentHeading}>Vad räknas som växtbaserat?</h5>
        <div className={styles.contentDescription}>
          <p>Inget kött/djur eller produkter från djur</p>
          <p>Inte växtbaserat: Ägg, mejeriprodukter, kött, kyckling, fisk</p>
          <p>Växtbaserat: Burgare gjord på baljväxter, linsbolognese, havre-crèmefraiche</p>
        </div>

        <h5 className={styles.contentHeading}>Vad räknas som en måltid?</h5>
        <div className={styles.contentDescription}>
          <p>Tre möjliga per dag: frukost/ mellanmål, lunch och middag</p>
        </div>
      </div>
    </div>
  )
}
export default Info