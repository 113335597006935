import React from "react";
import ReactDOM from "react-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import App from "./app/routes";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN;

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={AUTH_DOMAIN}
      clientId="140xD3OhkD2xyxJYjT1XDocgC8kjmo8H"
      redirectUri={window.location.origin}
      audience={`https://${AUTH_DOMAIN}/api/v2/`}
      scope="read:current_user"
      cacheLocation="localstorage"
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
