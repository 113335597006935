import React from 'react'
import moment from 'moment'
import { useDayzed } from 'dayzed'
import CalendarIcon from '../icons/Calendar'
import AngleIcon from '../icons/Angle'
import styles from '../styles/DatePicker.module.css'

function DatePicker(props) {
  const { calendars, getBackProps, getForwardProps, getDateProps } = useDayzed(props)
  const { isActive, setIsActive } = props

  const monthNamesShort = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'Maj',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Okt',
    'Nov',
    'Dec'
  ];
  const weekdayNamesShort = ['Mån', 'Tis', 'Ons', 'Tor', 'Fre', 'Lör', 'Sön'];

  return (
    <div>
      <button className={styles.datePickerButton} onClick={setIsActive} type="button">
        <span>{moment(props.selected).format('YYYY-MM-DD')}</span>
        <CalendarIcon color="#cf2e05" className={styles.calendarIcon} />
      </button>
      {isActive === true && calendars.length > 0 && (
        <div className={styles.datePicker}>
          {calendars.map(calendar => (
            <div key={`${calendar.month}${calendar.year}`}>
              <div className={styles.calendarHeading}>
                <button className={styles.prevButton} {...getBackProps({ calendars })}>
                  <span className={styles.hidden}>Föregående</span>
                  <AngleIcon className={styles.prevIcon} direction="left" color="#cf2e05" />
                </button>
                <span className={styles.monthYear}>
                  {monthNamesShort[calendar.month]} {calendar.year}
                </span>
                <button className={styles.nextButton} {...getForwardProps({ calendars })}>
                  <span className={styles.hidden}>Nästa</span>
                  <AngleIcon className={styles.nextIcon} direction="right" color="#cf2e05" />
                </button>
              </div>
              <div className={styles.weekdays}>
                {weekdayNamesShort.map(weekday => (
                  <div className={styles.weekdayLabel} key={`${calendar.month}${calendar.year}${weekday}`}>
                    {weekday}
                  </div>
                ))}
              </div>
              <div className={styles.monthSquares}>
                {calendar.weeks.map((week, weekIndex) =>
                  week.map((dateObj, index) => {
                    let key = `${calendar.month}${calendar.year}${weekIndex}${index}`;
                    if (!dateObj) {
                      return (
                        <div className={styles.emptySquare} key={key} />
                      );
                    }
                    let { date, selected, selectable } = dateObj;

                    return (
                      <button
                        className={!selectable ? styles.notSelectableButton : selected ? styles.squareButtonSelected : styles.squareButton}
                        key={key}
                        {...getDateProps({ dateObj })}
                      >
                        {date.getDate()}
                      </button>
                    );
                  })
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
export default DatePicker