import React from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import moment from 'moment'
import Question from "../components/Question";
import Intro from "../components/Intro";
import Content from "../components/layout/Content";
import Page from "../components/layout/Page";
import Info from "../components/Info";
import Arrow from "../icons/Arrow";
import DatePicker from "../components/DatePicker";
import styles from "../styles/Questions.module.css";

const API_URL = process.env.REACT_APP_API_BASEURL;
const AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN;

function Questions() {
  const navigate = useNavigate();
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const { teamId } = useParams();
  const [questions, setQuestions] = React.useState([]);
  const [fetching, setFetching] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [showIntroQuestions, setShowIntroQuestions] = React.useState(false);
  const [showQuestions, setShowQuestions] = React.useState(false);
  const [calendarIsVisible, setCalendarIsVisible] = React.useState(false)
  const [selectedDate, setSelectedDate] = React.useState(new Date())
  const [questionsLoaded, setQuestionsLoaded] = React.useState(false);
  const [answersByDate, setAnswersByDate] = React.useState([])

  const maxDate = new Date()

  let minDate = new Date()
  minDate.setDate(minDate.getDate() - 3);

  React.useEffect(() => {
    let componentMounted = true;

    async function getTeamsIntro() {
      setFetching(true);

      try {
        const accessToken = await getAccessTokenSilently({
          audience: `https://${AUTH_DOMAIN}/api/v2/`,
          scope: "read:current_user",
        });

        if (accessToken) {
          const { data } = await axios.get(`${API_URL}/teams/${teamId}/intro`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
          if (data && componentMounted) {
            setShowIntroQuestions(data?.showIntroQuestions);

            if (!data?.showIntroQuestions) {
              setShowQuestions(true);
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
      setFetching(false);
    }
    getTeamsIntro();

    return () => {
      componentMounted = false;
    };
  }, [teamId, getAccessTokenSilently]);

  React.useEffect(() => {
    let componentMounted = true;

    async function getQuestions() {
      setFetching(true);

      try {
        const accessToken = await getAccessTokenSilently({
          audience: `https://${AUTH_DOMAIN}/api/v2/`,
          scope: "read:current_user",
        });
        if (accessToken) {
          const { data } = await axios.get(
            `${API_URL}/teams/${teamId}/questions`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );

          if (data && data.hasOwnProperty("questions") && componentMounted) {
            setQuestions(data.questions);
          } else {
            setQuestions([]);
          }
        }
      } catch (error) {
        console.log(error);
        setQuestions([]);
      }
      setFetching(false);
      if (!questionsLoaded) {
        setQuestionsLoaded(true)
      }
    }
    if (showQuestions === true && teamId) {
      getQuestions();
    }

    return () => {
      componentMounted = false;
    };
  }, [questionsLoaded, showQuestions, teamId, getAccessTokenSilently]);

  React.useEffect(() => {
    let componentMounted = true

    async function getAnswers() {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: `https://${AUTH_DOMAIN}/api/v2/`,
          scope: "read:current_user",
        });
        if (accessToken) {
          const date = moment(selectedDate).format('YYYY-MM-DD')
          const { data } = await axios.get(`${API_URL}/teams/${teamId}/answers/${date}`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            })
          if (componentMounted && data && data.hasOwnProperty('answers')) {
            setAnswersByDate(data?.answers)
          } else {
            setAnswersByDate([])
          }
        } else {
          setAnswersByDate([])
        }
      } catch (error) {
        console.log(error)
      }

    }
    if (questionsLoaded === true && teamId) {
      getAnswers();
    }

    return () => {
      componentMounted = false
    }

  }, [selectedDate, questionsLoaded, teamId, getAccessTokenSilently])


  async function onSubmit(e) {
    e.preventDefault();
    setLoading(true);
    const elements = Array.from(e.currentTarget);

    const answers = elements
      .filter((item) => item.type === "range")
      .reduce((prev, current) => {
        return [
          ...prev,
          {
            question_id: parseInt(current.name),
            value: parseInt(current.value),
          },
        ];
      }, []);

    if (user) {
      try {
        const { email } = user;

        const date = moment(selectedDate).format('YYYY-MM-DD')

        const body = {
          user_id: email,
          answers,
          date,
        };

        const accessToken = await getAccessTokenSilently({
          audience: `https://${AUTH_DOMAIN}/api/v2/`,
          scope: "read:current_user",
        });
        if (accessToken) {
          const { data } = await axios.post(
            `${API_URL}/teams/${teamId}/questions`,
            body,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          if (data && data.hasOwnProperty("answers")) {
            navigate(`/teams/${teamId}`);
          }
        } else {
          throw new Error();
        }
      } catch (error) {
        console.log(error);
      }
    }
    setLoading(false);
  }

  function onIntroCompleted() {
    setShowIntroQuestions(false);
    navigate(`/teams/${teamId}`);
  }

  return (
    isAuthenticated && (
      <>
        {fetching === true ? (
          <div className={styles.loading}>
            <p className={styles.loadingText}>Laddar…</p>
          </div>
        ) : showIntroQuestions === true ? (
          <Page>
            <Intro onCompleted={onIntroCompleted} />
          </Page>
        ) : questions && questions.length > 0 ? (
          <Page>
            <div className={styles.subHeader}>
              <Content style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
                <Link className={styles.backButton} to={`/teams/${teamId}`}>
                  <Arrow
                    className={styles.backIcon}
                    direction="left"
                    color="#cf2e05"
                  />
                  Tillbaka
                </Link>
                <div className={styles.calendarWrapper}>
                  <DatePicker minDate={minDate} maxDate={maxDate} isActive={calendarIsVisible} setIsActive={() => setCalendarIsVisible(prev => !prev)} firstDayOfWeek={1} onDateSelected={({ date }) => {
                    setSelectedDate(date)
                    setCalendarIsVisible(false)
                  }} selected={selectedDate} />
                </div>
              </Content>
            </div>
            <Content style={{ paddingTop: "2rem" }}>
              <div className={styles.form}>
                <h3 className={styles.questionHeading}>
                  Hur har du ätit idag?
                </h3>
                <form onSubmit={onSubmit}>
                  {questions.map((item, index) => {
                    item.label = "g";
                    if (index !== 0) {
                      item.label = "st";
                    }
                    let registeredAnswer = 0
                    if (answersByDate && answersByDate.length === 3) {
                      registeredAnswer = answersByDate[index].value
                    }
                    return (<Question key={index} index={index} item={item} answer={registeredAnswer} />)
                  })}
                  <button
                    className={styles.submitButton}
                    disabled={loading}
                    type="submit"
                  >
                    Spara dagens resultat
                  </button>
                </form>
              </div>
            </Content>
            <Info />
          </Page>
        ) : (
          <div className={styles.loading}>
            <p className={styles.loadingText}>Inga frågor</p>
          </div>
        )}
      </>
    )
  );
}
export default Questions;
