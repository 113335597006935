import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import Page from "../components/layout/Page";
import styles from "../styles/Teams.module.css";

const API_URL = process.env.REACT_APP_API_BASEURL;
const AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN;

function Teams() {
  const { isAuthenticated, getAccessTokenSilently, logout } = useAuth0();
  const navigate = useNavigate();

  const [teams, setTeams] = React.useState([]);
  const [fetching, setFetching] = React.useState(false);

  React.useEffect(() => {
    let componentMounted = true;

    async function getTeams() {
      setFetching(true);

      try {
        const accessToken = await getAccessTokenSilently({
          audience: `https://${AUTH_DOMAIN}/api/v2/`,
          scope: "read:current_user",
        });

        if (accessToken) {
          const { data } = await axios.get(`${API_URL}/teams`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          if (data && data.hasOwnProperty("teams") && componentMounted) {
            if (data.teams.length === 1) {
              const { id } = data.teams[0];
              navigate(`/teams/${id}`);
            } else {
              setTeams(data.teams);
            }
          } else {
            setTeams([]);
          }
        }
      } catch (error) {
        console.log(error);
        setTeams([]);
      }
      setFetching(false);
    }
    getTeams();

    return () => {
      componentMounted = false;
    };
  }, [getAccessTokenSilently, navigate]);

  return (
    isAuthenticated && (
      <>
        {fetching === true ? (
          <div className={styles.loading}>
            <p className={styles.loadingText}>Hämtar team…</p>
          </div>
        ) : teams && teams.length > 0 ? (
          <Page>
            <div className={styles.content}>
              <h1 className={styles.heading}>Mina lag</h1>
              <ul className={styles.list}>
                {teams.map(({ id, link_id, name }) => (
                  <li className={styles.item} key={id}>
                    <Link className={styles.link} to={`/teams/${id}`}>
                      {name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </Page>
        ) : (
          <Page>
            <div>
              Inga team kunde hittas
              <button
                type="button"
                onClick={() => logout({ returnTo: window.location.origin })}
              >
                Logga ut
              </button>
            </div>
          </Page>
        )}
      </>
    )
  );
}
export default Teams;
