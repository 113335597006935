import * as React from 'react';
import { BrowserRouter, Routes, Route} from "react-router-dom";
import Teams from '../pages/Teams'
import Questions from '../pages/Questions'
import Login from '../pages/Login'
import Status from '../pages/Status'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path='/teams' element={<Teams />} />
        <Route exact path='/teams/:teamId/questions' element={<Questions />} />
        <Route exact path="/teams/:teamId" element={<Status />} />
        <Route path='/' element={<Login />} />
      </Routes>
    </BrowserRouter>
    )
}

export default App