import React from "react";

function More({ color = "#3a3a3a", className }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
    >
      <g fill="none" fillRule="evenodd">
        <path fill="none" d="M0 0h32v32H0z" />
        <path
          fill={color}
          d="M16 14a2 2 0 1 1-.001 4.001A2 2 0 0 1 16 14zm-7 0a2 2 0 1 1-.001 4.001A2 2 0 0 1 9 14zm14 0a2 2 0 1 1-.001 4.001A2 2 0 0 1 23 14z"
        />
      </g>
    </svg>
  );
}

export default More;
