import React from "react";
import styles from "../styles/Slider.module.css";

function Slider({
  name = "slider",
  color = "#e13205",
  min,
  max,
  value,
  label,
  onChange,
}) {
  function getPercentageFrom(currentValue, maxValue, minValue) {
    let result = 0;

    try {
      const percentage =
        (parseInt(currentValue) - parseInt(minValue)) /
        (parseInt(maxValue) - parseInt(minValue));
      if (percentage) {
        result = percentage * 100;
      }
    } catch (error) {
      console.log("Failed to parse", error);
    }
    return result;
  }

  return (
    <div className={styles.container}>
      <div style={{ color: color }} className={styles.slider}>
        <div
          className={styles.fill}
          style={{ width: `${getPercentageFrom(value, max, min)}%` }}
        ></div>
        <input
          className={styles.input}
          name={name}
          value={value || 0}
          onChange={onChange}
          type="range"
          min={parseInt(min)}
          max={parseInt(max)}
        />
        <div className={styles.bgFill}></div>
      </div>
      <div className={styles.labels}>
        <span>
          {min} {label}
        </span>
        <span>
          {max} {label}
        </span>
      </div>
    </div>
  );
}

export default Slider;
