import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import axios from "axios";
import More from "../icons/More";
import styles from "../styles/Leaderboard.module.css";

const API_URL = process.env.REACT_APP_API_BASEURL;
const AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN;

function Leaderboard() {
  const { getAccessTokenSilently } = useAuth0();
  const { teamId } = useParams();
  const [leaderboard, setLeaderboard] = React.useState([]);
  const [extraTeams, setExtraTeams] = React.useState([]);
  const [fetching, setFetching] = React.useState(false);

  React.useEffect(() => {
    let componentMounted = true;

    async function getLeaderboard() {
      setFetching(true);
      try {
        const accessToken = await getAccessTokenSilently({
          audience: `https://${AUTH_DOMAIN}/api/v2/`,
          scope: "read:current_user",
        });

        if (accessToken) {
          const { data } = await axios.get(`${API_URL}/leaderboard/${teamId}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
          if (componentMounted && data && data.hasOwnProperty("leaderboard")) {
            setLeaderboard(data?.leaderboard);
            setExtraTeams(data?.extraTeams);
          }
        }
      } catch (error) {
        console.log(error);
      }
      setFetching(false);
    }
    getLeaderboard();

    return () => {
      componentMounted = false;
    };
  }, [getAccessTokenSilently, teamId]);

  return (
    <>
      <div className={styles.content}>
        <h1 className={styles.heading}>Topplista</h1>
        {fetching === true ? (
          <div>Hämtar topplistan</div>
        ) : (
          <>
            <ol className={styles.list}>
              {leaderboard &&
                leaderboard.map(({ name, score, isMyTeam }, index) => (
                  <li className={styles.item} key={index}>
                    <p className={styles.name}>
                      {name}
                      {isMyTeam === true && (
                        <span className={styles.isMyTeam}>Mitt team</span>
                      )}
                    </p>
                    <span>{score} p</span>
                  </li>
                ))}
            </ol>
            {extraTeams && extraTeams.length > 0 && (
              <>
                <div className={styles.divider}>
                  <More className={styles.moreIcon} color="currentColor" />
                </div>
                <ul className={styles.extraTeamList}>
                  {extraTeams.map(({ name, score, position, isMyTeam }, index) => (
                    <li className={styles.extraItem} key={index}>
                      <p className={styles.name}>
                        <span className={styles.position}>{position}.</span>{" "}
                        {name}
                        {isMyTeam === true && (
                          <span className={styles.isMyTeam}>Mitt team</span>
                        )}
                      </p>
                      <span>{score} p</span>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}
export default Leaderboard;
