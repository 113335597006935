import React from 'react'
import moment from 'moment'
import styles from '../styles/NotStarted.module.css'

function NotStarted({ startDate }) {
  return (
    <div className={styles.notStartedPage}>
      <div className={styles.content}>
        <h1 className={styles.heading}>Snart börjar det!</h1>
        <p className={styles.preamble}>
          {startDate && (
            <>Utmaningen börjar {moment(startDate).format('YYYY-MM-DD')}. </>
          )}
          Redan nu kan du ladda upp inför Vegovalet genom att spana in vår inspirationssida!
        </p>
        <a className={styles.inspirationButton} href="https://www.ica.se/kampanj/vegovalet/">
          Till inspiration
        </a>
      </div>
    </div>
  )
}

export default NotStarted